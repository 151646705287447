<template>
  <section>
    <v-card class="v-card--auth pa-5 outlined" max-width="500" outlined>
      <v-container pa-0 grid-list-xl>
        <h1 class="headline font-weight-regular primary--text">Log ind</h1>
        <v-form ref="form" v-model="form" @keyup.native.enter="signIn" class="mb-5"
          style="padding: 1rem 1rem 0rem 1rem">
          <v-container pa-0>
            <v-row>
              <v-text-field autofocus v-model="email" @input="setAuthEmail" label="Email" name="email"
                persistent-hint />
            </v-row>
            <v-row>
              <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'" label="Kodeord"
                @click:append="showPassword = !showPassword" />
            </v-row>
            <v-row>
              <v-btn color="accent" @click="signIn" v-bind:loading="isLoading">Log ind</v-btn>
            </v-row>
            <v-spacer></v-spacer>
            <v-row justify="end" align="end" style="font-size: 0.75rem">
              <router-link :to="{ name: 'forgotpassword' }" v-slot="{ href, navigate }">
                <a :href="href" @click="navigate">Glemt kodeord?</a>
              </router-link>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    const data = {
      email: this.$store.state.authEmail,
      password: undefined,

      form: false,
      isLoading: false,
      showPassword: false,
    };
    return data;
  },
  methods: {
    ...mapActions("cognito", ["signInUser"]),
    ...mapMutations(["setSnackbar", "setAuthEmail"]),
    signIn() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      this.signInUser({
        username: this.email,
        password: this.password,
      })
        .then(() => {
          this.setSnackbar({
            type: "success",
            msg: "Du er nu logget ind",
          });

          const redirect = this.$route.query.redirect;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({ name: "profile" });
          }
        })
        .catch((err) => {
          this.setSnackbar({
            type: "error",
            msg: err.message,
            timeout: 10000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
